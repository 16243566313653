import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from '@reach/router';
import 'react-toastify/dist/ReactToastify.css';
import { useWallet } from '@solana/wallet-adapter-react';

import { WalletProviderContext, WalletConnectButton } from '../components/WalletConnectButton';
// import { Workspace } from '../components/Workspace';


import config from '../../config'

const WhitelistSubmitSuccessPage = () => {

  return (
    <>
      <title>IRON POC</title>
      <h1>IRON whitelist submit success Page</h1>
    </>
  )
}

const WhitelistRequestFrom = () => {
  const tiers = [{ name: "sliver", amount: 10}, { name: "gold", amount: 30}, { name: "Platimum", amount: 100}]
  const [selectedTier, setSelectedTier] = useState(100)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { publicKey } = useWallet();

  const handleOptionChange = async (event) => {
    console.log(event.target.value);
    setSelectedTier(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();    
    const token = searchParams.get("token");
    const queryParam = new URLSearchParams({token: token})
    const body = JSON.stringify({
      address: publicKey,
      tier: selectedTier
    })

    console.log("WhitelistRequestFrom handleSubmit", body);

    const response = await fetch(
      `${config.backend_api_url}/whitelist/submitWhiteListRequest?${queryParam}`,{
        method: 'POST',
        body: body,
        headers: {
          'content-type': 'application/json'
        },
    })
    const response_body = await response.json()
    console.log("WhitelistRequestFrom handleSubmit response_body",response_body)

    if(response_body.success == true){
      toast.success(
        <p> Success, you are in whitelist now! </p>
        ,
        {
          theme: "colored",
          autoClose: true,
          position: toast.POSITION.TOP_RIGHT,
          closeOnClick: false
        }
      )
    } else {
      toast.warn(
        <p> Fail to sumbit whitelist request </p>
        ,
        {
          theme: "colored",
          autoClose: true,
          position: toast.POSITION.TOP_RIGHT,
          closeOnClick: false
        }
      )
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {
        tiers.map( ({name, amount}) => {
          return (
            <label>
              <input type="radio" value={amount} 
                      checked={selectedTier == amount} 
                      onChange={handleOptionChange} />
              {name}
            </label>
          )
        })
      }
      <input type="submit" />
    </form>
  )
}

// styles
// TODO
const WhitelistRegisterPage = () => {
  return (
    <>
      <ToastContainer />
      <WalletProviderContext>
        <title>IRON POC</title>
        <h1>IRON whitelist Page</h1>
        <WalletConnectButton />
        <WhitelistRequestFrom />
      </WalletProviderContext>
    </>
  )
}


const WhitelistTokenErrorPage = () => {
  return (
    <>
      <title>IRON POC</title>
      <h1>IRON whitelist Error Page</h1>
    </>
  )
}

const WhitelistPageInner = () => {
  const location = useLocation();
  const [isValidToken, setIsValidToken ] = useState(false)
  const searchParams = new URLSearchParams(location.search);

  const verifyToken = async () => {
    let token = searchParams.get("token");
    console.log("token", token)
    const queryParam = new URLSearchParams({token: token})
    try {
      const response = await fetch(`${config.backend_api_url}/whitelist/verify?${queryParam}`)
      const response_body = await response.json() 
      setIsValidToken(response_body.success)
    } catch (error) {
      setIsValidToken(false)
    }
  }
  verifyToken()
  return (
    <>
      { 
        isValidToken
          ? <WhitelistRegisterPage/>
          : <WhitelistTokenErrorPage /> 
      }
    </>
  )
}

// markup
const WhitelistPage = () => {
  return (
    <>
      <WhitelistPageInner />
    </>
  )
}

export default WhitelistPage
